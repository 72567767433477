import React from 'react';
import Layout from '../../components/Layout';
import logo from '../../assets/images/logo.png';

import pic1 from '../../assets/images/jdc.png';
import pic2 from '../../assets/images/The_Chase_Vol_1.png';

import config from '../../../config';

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>J.D. Cravens</h2>
          <p>Austin, TX Singer/ Songwriter / Bassist / Producer</p>
        </div>
      </header>
      <div className="wrapper">
        <div className="inner">
          <section>
            <h3 className="major">The J.D. Cravens Discography</h3>
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Release</th>
                    <th>Title</th>
                    <th>Where to Listen</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>2002</td>
                    <td><b>Tuscarawas River Band - Best of the Jamm Tour</b>
                    <p>Lead Vocals and Bass Guitar</p>
                    </td>
                    <td>
                      <ul className="icons icons-header">

                        <li>
                          <a href="https://soundcloud.com/sessionswest/sets/the-tuscarawas-river-band-best" className="icon fa-soundcloud">
                            <span className="label">Soundcloud</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://archive.org/details/TuscarawasRiverBandTheRoadGoesOnForever" className="icon fa-creative-commons">
                            <span className="label">Internet Archive</span>
                          </a>
                        </li>
                        {/*<li>
                          <a href="/#" className="icon fa-youtube">
                            <span className="label">Youtube</span>
                          </a>
                        </li>
                         <li>
                          <a href="/#" className="icon fa-facebook">
                            <span className="label">Facebook</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-instagram">
                            <span className="label">Instagram</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-twitter">
                            <span className="label">Twitter</span>
                          </a>
                        </li> */}
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>2003</td>
                    <td>
                      <b>Kreutsberg Acoustic Sessions</b>
                      <p>Lead Vocals and Acoustic Guitar</p>
                    </td>
                    <td>
                      <ul className="icons icons-header">
                        {/* <li>
                          <a href="/#" className="icon fa-spotify">
                            <span className="label">Spotify</span>
                          </a>
                        </li> */}
                        <li>
                          <a href="https://soundcloud.com/sessionswest/sets/kreutsberg-acoustic-sessions-c" className="icon fa-soundcloud">
                            <span className="label">Soundcloud</span>
                          </a>
                        </li>
                        {/* <li>
                          <a href="/#" className="icon fa-youtube">
                            <span className="label">Youtube</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-facebook">
                            <span className="label">Facebook</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-instagram">
                            <span className="label">Instagram</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-twitter">
                            <span className="label">Twitter</span>
                          </a>
                        </li> */}
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>2008</td>
                      <td>
                        <b>Peacefield - Stars Away</b>
                        <p>Bass Guitar</p>
                      </td>
                    <td>
                      <ul className="icons icons-header">
                        <li>
                          <a href="https://open.spotify.com/album/6N8XdBJtEkYmQhs2GVi4vH?si=B4FOWL3BSF2WPFvibol17g" className="icon fa-spotify">
                            <span className="label">Spotify</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://soundcloud.com/peacefield" className="icon fa-soundcloud">
                            <span className="label">Soundcloud</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://music.amazon.com/albums/B0034YMERG?marketplaceId=ATVPDKIKX0DER&musicTerritory=US" className="icon fa-amazon">
                            <span className="label">Amazon</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://music.apple.com/us/album/stars-away/350942022" className="icon fa-apple">
                            <span className="label">Apple</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://youtu.be/EdXbLMEM26s" className="icon fa-youtube">
                            <span className="label">Youtube</span>
                          </a>
                        </li>
                        {/* <li>
                          <a href="/#" className="icon fa-facebook">
                            <span className="label">Facebook</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-instagram">
                            <span className="label">Instagram</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-twitter">
                            <span className="label">Twitter</span>
                          </a>
                        </li> */}
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>2020</td>
                      <td>
                        <b>Jesse Cravens - The Chase Vol. 1</b>
                        <p>Production, Vocals, Acoustic Guitar, Bass Guitar</p>
                      </td>
                    <td>
                      <ul className="icons icons-header">
                        {/* <li>
                          <a href="/#" className="icon fa-spotify">
                            <span className="label">Spotify</span>
                          </a>
                        </li> */}
                        <li>
                          <a href="https://soundcloud.com/sessionswest/sets/jesse-cravens-the-chase-1" className="icon fa-soundcloud">
                            <span className="label">Soundcloud</span>
                          </a>
                        </li>
                        {/* <li>
                          <a href="/#" className="icon fa-youtube">
                            <span className="label">Youtube</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-facebook">
                            <span className="label">Facebook</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-instagram">
                            <span className="label">Instagram</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-twitter">
                            <span className="label">Twitter</span>
                          </a>
                        </li> */}
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>2021</td>
                    <td>
                      <b>The Colorado River Boys Live Album - 2021.06.12 - Twin Creeks Icehouse, Volente, TX</b>
                      <p>Production, Bass Guitar</p>
                    </td>
                    <td>
                      <ul className="icons icons-header">
                        {/* <li>
                          <a href="/#" className="icon fa-spotify">
                            <span className="label">Spotify</span>
                          </a>
                        </li> */}
                        <li>
                          <a href="https://soundcloud.com/sessionswest/sets/2021-06-12-crb-twin-creeks" className="icon fa-soundcloud">
                            <span className="label">Soundcloud</span>
                          </a>
                        </li>
                        {/* <li>
                          <a href="/#" className="icon fa-youtube">
                            <span className="label">Youtube</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-facebook">
                            <span className="label">Facebook</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-instagram">
                            <span className="label">Instagram</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-twitter">
                            <span className="label">Twitter</span>
                          </a>
                        </li> */}
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>2023</td>
                    <td>
                      <b>The Colorado River Boys Live Album - Tulsa Time - Single Release - 2021.06.12 - Twin Creeks Icehouse, Volente, TX</b>
                      <p>Production, Vocals, Bass Guitar</p>
                    </td>
                    <td>
                      <ul className="icons icons-header">
                         <li>
                          <a href="https://music.apple.com/us/album/tulsa-time-single/1696278072" className="icon fa-apple">
                            <span className="label">Apple Music</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://amazon.com/music/player/albums/B0CBC8MBNC?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_Gxdtkh7AvLTU8baD1laGKjDOM" className="icon fa-amazon">
                            <span className="label">Amazon Music</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://open.spotify.com/track/0qKLDEnpnkicL6fBXmyrus?si=8ba02903f9fe4f16" className="icon fa-spotify">
                            <span className="label">Spotify Music</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://soundcloud.com/sessionswest/oh-my-sweet-carolina" className="icon fa-soundcloud">
                            <span className="label">Soundcloud</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://music.youtube.com/playlist?list=OLAK5uy_ncY5lSpbBK1Irts3--Y7fYR8ElMOXkCmk&feature=share" className="icon fa-youtube">
                            <span className="label">Youtube</span>
                          </a>
                        </li>
                        {/* <li>
                          <a href="/#" className="icon fa-facebook">
                            <span className="label">Facebook</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-instagram">
                            <span className="label">Instagram</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-twitter">
                            <span className="label">Twitter</span>
                          </a>
                        </li> */}
                      </ul>
                    </td>                    
                  </tr>
                  <tr>
                    <td>2023</td>
                    <td>
                      <b>J.D. Cravens - Single Release - The Road To Terlingua</b>
                      <p>Production, Vocals, Acoustic Guitar, Bass Guitar</p>
                    </td>
                    <td>
                      <ul className="icons icons-header">
                        <li>
                          <a href="https://music.apple.com/us/album/the-road-to-terlingua/1695831804?i=1695831805" className="icon fa-apple">
                            <span className="label">Apple Music</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://amazon.com/music/player/albums/B0CB5961VM?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_ImqEBaCu7qkraMZdHBYm5o1BT" className="icon fa-amazon">
                            <span className="label">Amazon Music</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://open.spotify.com/track/5fNzYzZHbasfHp8qFRvHjP?si=936307b7822b449b" className="icon fa-spotify">
                            <span className="label">Spotify Music</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://soundcloud.com/sessionswest/terlingua" className="icon fa-soundcloud">
                            <span className="label">Soundcloud</span>
                          </a>
                        </li>
                         <li>
                          <a href="https://music.youtube.com/watch?v=8You7eiDiVg&feature=share" className="icon fa-youtube">
                            <span className="label">Youtube</span>
                          </a>
                        </li>
                        {/* <li>
                          <a href="/#" className="icon fa-facebook">
                            <span className="label">Facebook</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-instagram">
                            <span className="label">Instagram</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-twitter">
                            <span className="label">Twitter</span>
                          </a>
                        </li> */}
                      </ul>
                    </td>
                    </tr>
                    <tr>
                    <td>2023</td>
                    <td>
                      <b>J.D. Cravens - Single Release - Oh My Sweet Carolina by Ryan Adams</b>
                      <p>Production, Vocals, Acoustic Guitar, Bass Guitar, Drums</p>
                    </td>
                    <td>
                      <ul className="icons icons-header">
                        <li>
                          <a href="https://music.apple.com/us/album/oh-my-sweet-carolina/1696278730?i=1696278733" className="icon fa-apple">
                            <span className="label">Apple Music</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://amazon.com/music/player/albums/B0CBC88M6D?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_NMnZNpXoiduIjJUj2p1vwQOdD" className="icon fa-amazon">
                            <span className="label">Amazon Music</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://open.spotify.com/track/2JgANm5sfHNsUrE9EO8Nee?si=4136d79d594d4fbf" className="icon fa-spotify">
                            <span className="label">Spotify Music</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://soundcloud.com/sessionswest/oh-my-sweet-carolina" className="icon fa-soundcloud">
                            <span className="label">Soundcloud</span>
                          </a>
                        </li>
                         <li>
                          <a href="https://music.youtube.com/watch?v=t_2IfdELK50&feature=share" className="icon fa-youtube">
                            <span className="label">Youtube</span>
                          </a>
                        </li>
                        {/* <li>
                          <a href="/#" className="icon fa-facebook">
                            <span className="label">Facebook</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-instagram">
                            <span className="label">Instagram</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-twitter">
                            <span className="label">Twitter</span>
                          </a>
                        </li> */}
                      </ul>
                    </td>                    
                  </tr>
                  <tr>
                    <td>2023</td>
                    <td>
                      <b>J.D. Cravens - Single Release - The Fight to Stay Free</b>
                      <p>Production, Vocals, Acoustic Guitar, Bass Guitar, Drums</p>
                    </td>
                    <td>
                      <ul className="icons icons-header">
                        <li>
                          <a href="https://music.apple.com/us/album/fight-to-stay-free/1699119552?i=1699119553" className="icon fa-apple">
                            <span className="label">Apple Music</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://amazon.com/music/player/albums/B0CCQMF18B?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_u08emPgcEA3bylFpZxNd3tcsO" className="icon fa-amazon">
                            <span className="label">Amazon Music</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://open.spotify.com/track/0LotP53CoNkPSEzvyYqwZi?si=3624c5c9d8a34cc5" className="icon fa-spotify">
                            <span className="label">Spotify Music</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://soundcloud.com/sessionswest/oh-my-sweet-carolina" className="icon fa-soundcloud">
                            <span className="label">Soundcloud</span>
                          </a>
                        </li>
                         <li>
                          <a href="https://music.youtube.com/watch?v=qHXYHjI2qys&feature=share" className="icon fa-youtube">
                            <span className="label">Youtube</span>
                          </a>
                        </li>
                        {/* <li>
                          <a href="/#" className="icon fa-facebook">
                            <span className="label">Facebook</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-instagram">
                            <span className="label">Instagram</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-twitter">
                            <span className="label">Twitter</span>
                          </a>
                        </li> */}
                      </ul>
                    </td>                    
                  </tr>
                  <tr>
                    <td>2023</td>
                    <td>
                      <b>J.D. Cravens - Single Release - Leavin' Bowling Green</b>
                      <p>Production, Vocals, Acoustic Guitar, Bass Guitar, Drums</p>
                    </td>
                    <td>
                      <ul className="icons icons-header">
                        <li>
                          <a href="https://music.apple.com/us/album/leavin-bowling-green/1703417299?i=1703417300" className="icon fa-apple">
                            <span className="label">Apple Music</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://amazon.com/music/player/albums/B0CG2Z8TRY?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_YvXo3jIGdvAVi0cJ0N3vkGkr4" className="icon fa-amazon">
                            <span className="label">Amazon Music</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://open.spotify.com/album/42XShyyijL2EEoLBtRGPTz?si=rWaZStupSvCkhfvA45eAwg" className="icon fa-spotify">
                            <span className="label">Spotify Music</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://soundcloud.com/sessionswest/oh-my-sweet-carolina" className="icon fa-soundcloud">
                            <span className="label">Soundcloud</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://music.youtube.com/watch?v=0QMNym3GOLg&feature=share" className="icon fa-youtube">
                            <span className="label">Youtube</span>
                          </a>
                        </li>
                        {/* <li>
                          <a href="/#" className="icon fa-facebook">
                            <span className="label">Facebook</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-instagram">
                            <span className="label">Instagram</span>
                          </a>
                        </li>
                        <li>
                          <a href="/#" className="icon fa-twitter">
                            <span className="label">Twitter</span>
                          </a>
                        </li> */}
                      </ul>
                    </td>                    
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="2"></td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </section>

          <section className="artist">
            <h3 className="major">Bio</h3>
            <p>
              Jesse Cravens is a singer songwriter, bass player based out of
              Austin, TX. In the late 1990's, Jesse spent three years touring
              the continental US, playing over 200 shows a year, and learning
              the ropes as a frontman, songwriter, bass player.
            </p>
            <span className="image right">
              <img src={pic2} alt="Jesse Cravens - The Chase Vol. 1" />
            </span>

            <p>
              During that time, Jesse opened for Colonel Bruce Hampton and the
              Codetalkers, JJ Grey and Mofro, Vasser Clements and Northern
              Lights, Ekoostic Hookah, Perpetual Groove to name a few and played
              at iconic venues such as the Cleveland's Agora Theater, the
              Newport Music Hall in Columbus, Colorado's Rico Theater during the
              2001 Telluride Blugrass Festival, a late night show at the 2002
              New Orleans Jazz Festival, the Pourhouse in Raleigh, NC and
              Austin's own Flamingo Cantina and Momo's.
            </p>

            <p>
              Later, betwen 2007-2009, Jesse joined the Pittsburgh-based band
              Peacefield as their bass player to help build their new Texas
              sound, and recorded the bass tracks on 'Stars Away' which was
              produced by Frogville Records producer Bill Palmer of Austin's
              Sharecroppers and Hundred Year Flood fame.
            </p>

            <p>
              Today, Jesse plays bass and is a founding member of the Colorado River Boys 
              and is also member of the Hill Country Bible Church worship band.
            </p>
          </section>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
